export const COOKIE_BANNER_EN = {
    title: "Cookie Notice",
    without_accepting: "Continue without Accepting",
    description:
        "We use cookies or similar technologies on our sites to perform analytics, provide you with tailored content, and make your experience better. By clicking 'Allow', you agree to our use of cookies on the journey you take with us. Learn more by reading",
    description_link: "our cookie policy",
    button_manage: "Manage",
    button_reject_all: "Reject All",
    button_allow_all: "Allow All",
};
