import React from 'react'
import Login from './Login/Login'
import Register from './Register/Register'

function Auth() {
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Login />
        <Register />
    </div>
  )
}

export default Auth