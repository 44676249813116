import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TariffPlanItem from "./TariffPlanItem/TariffPlanItem";
import { tariffPlans } from "./tariffPlans";
import { useTranslation } from "react-i18next";

import styles from "./Pricing.module.scss";

const Pricing = () => {

	const { t } = useTranslation("pricing");

    return (
        <Container fluid className={styles.pricing}>
            <Container>
				<Row>
					<Col sm={12}>
						<h2>{t("title_main")}</h2>
                    </Col>
                </Row>
                
				<Row>
                    <Col sm={12} className={styles.pricing_description}>
                        <p>{t("description_main")}</p>
                    </Col>
                </Row>


                <Row>
					<Col sm={12} className={styles.tariffs_container}>
						{tariffPlans.map((plan, index) => (
							<TariffPlanItem
								key={index}
								title={plan.title}
								price={plan.price}
								buttonText={plan.buttonText}
								features={plan.features}
								tariffType={plan.tariffType}
								description={plan.description}
							/>
						))}
					</Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Pricing;
