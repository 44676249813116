import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import CookieManage from "../CookieManage/CookieManage";
import Cookies from 'js-cookie';
import { useScrollContext } from "../../../Provider/ScrollContext";
import { useTranslation } from "react-i18next";

import styles from "./CookieBanner.module.scss";

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [showManage, setShowManage] = useState(false);
    const [initialPreferences, setInitialPreferences] = useState({
        Targeting: false,
        Functional: false,
        Performance: false,
        Necessary: true
    });

    const { t } = useTranslation("cookieBanner");

    const { isArrowVisible } = useScrollContext(); // состояние из контекста
    const bannerStyle = isArrowVisible ? styles.bannerLeft : styles.banner; // другой стиль при видимости якоря из CookiePolicy

    useEffect(() => {
        const cookiePreferences = Cookies.get('cookiePreferences');
        if (!cookiePreferences) {
            setShowBanner(true);
        } else {
            const parsedPreferences = JSON.parse(cookiePreferences);
            setInitialPreferences(parsedPreferences.preferences);
        }
    }, []);

    const handleCloseManage = () => setShowManage(false);
    const handleShowManage = () => setShowManage(true);

    const handleOtherPageClick = () => {
        window.scrollTo(0, 0);
    };

    const handleRejectAll = () => {
        const cookiePreferences = {
            accepted: false,
            preferences: {
                Targeting: false,
                Functional: false,
                Performance: false,
                Necessary: true
            }
        };
        Cookies.set('cookiePreferences', JSON.stringify(cookiePreferences), { expires: 3650 });
        setShowBanner(false);
    };

    const handleAllowAll = () => {
        const cookiePreferences = {
            accepted: true,
            preferences: {
                Targeting: true,
                Functional: true,
                Performance: true,
                Necessary: true
            }
        };
        Cookies.set('cookiePreferences', JSON.stringify(cookiePreferences), { expires: 3650 });
        setShowBanner(false);
    };

    const handleSavePreferences = (preferences, accepted) => {
        const cookiePreferences = {
            accepted,
            preferences
        };
        Cookies.set('cookiePreferences', JSON.stringify(cookiePreferences), { expires: 3650 });
        setShowBanner(false);
        setShowManage(false);
    };

    if (!showBanner) {
        return null;
    }

    return (
        <>
            <div className={bannerStyle}>
                <p className={styles.without_accepting} onClick={handleRejectAll}>{t("without_accepting")}</p>

                <h4>{t("title")}</h4>

                <p className={styles.text}>
                    {t("description")} {" "}

                    <Link to="/cookie-policy" onClick={handleOtherPageClick} className={styles.link}>
                        {t("description_link")}
                    </Link>.
                </p>

                <div className={styles.buttons_container}>
                    <Button onClick={handleShowManage}>{t("button_manage")}</Button>

                    <div className={styles.buttons_actions}>
                        <Button onClick={handleRejectAll}>{t("button_reject_all")}</Button>
                        <Button onClick={handleAllowAll}>
                            {t("button_allow_all")}
                        </Button>
                    </div>
                </div>
            </div>

            <CookieManage
                show={showManage}
                handleClose={handleCloseManage}
                handleSavePreferences={handleSavePreferences}
                initialPreferences={initialPreferences}
            />
        </>
    );
};

export default CookieBanner;
