import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SliderReviews from "./SliderReviews/SliderReviews";
import { useTranslation } from "react-i18next";

import styles from "./Reviews.module.scss";

const Reviews = () => {

    const { t } = useTranslation("reviews");

    return (
        <Container fluid className={styles.reviews}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h2>{t("title_main")}</h2>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className={styles.reviews_description}>
                        <p>{t("description_main")}</p>
                    </Col>
                </Row>

                <Row>
                    <SliderReviews />
                </Row>
            </Container>
        </Container>
    );
};

export default Reviews;
