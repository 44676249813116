import React from "react";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";


const CookieTable = ({ cookieData }) => {
    const currentHostname = window.location.hostname;

    return (
        <Table striped bordered hover>
            <thead>
                <tr style={{ borderColor: "#b1b1b1" }}>
                    <th style={{ backgroundColor: "#10062b", color: "#f6f6f6" }}>Cookie Subgroup</th>
                    <th style={{ backgroundColor: "#10062b", color: "#f6f6f6" }}>Cookies</th>
                    <th style={{ backgroundColor: "#10062b", color: "#f6f6f6" }}>Cookies used</th>
                </tr>
            </thead>
            <tbody>
                {cookieData.map((item, index) => (
                    <tr style={{ borderColor: "#b1b1b1" }} key={index}>
                        <td style={{ backgroundColor: "#10062b", color: "#f6f6f6" }}>{index === 0 ? currentHostname : item.subgroup}</td>
                        <td style={{ backgroundColor: "#10062b", color: "#f6f6f6" }}>{item.cookies}</td>
                        <td style={{ backgroundColor: "#10062b", color: "#f6f6f6" }}>{item.type}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

CookieTable.propTypes = {
    cookieData: PropTypes.arrayOf(
        PropTypes.shape({
            subgroup: PropTypes.string.isRequired,
            cookies: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default CookieTable;
