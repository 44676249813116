export const PRIVACY_POLICY_RU = {
    title_main: "Польитика конфиденциальности",
    description_main:
        "Настоящая политика конфиденциальности применяется к приложению Clicked Ai (далее именуемому 'Приложение') для мобильных устройств, созданному компанией Clicked (далее именуемой 'Поставщик услуг') как бесплатный сервис. Этот сервис предназначен для использования 'КАК ЕСТЬ'.",

    title_information_collection: "Сбор и Использование Информации",
    description_information_collection: "Приложение собирает информацию, когда вы загружаете и используете его. Эта информация может включать такие данные, как",
    li_information_collection_first: "IP-адрес вашего устройства (например, IP-адрес)",
    li_information_collection_second: "Страницы Приложения, которые вы посещаете, время и дата вашего визита, время, проведенное на этих страницах",
    li_information_collection_third: "Время, проведенное в Приложении",
    li_information_collection_fourth: "Операционная система, которую вы используете на своем мобильном устройстве",
    paragraph_first_information_collection: "Приложение не собирает точную информацию о местоположении вашего мобильного устройства.",
    paragraph_second_information_collection: "Поставщик услуг может использовать предоставленную вами информацию для связи с вами время от времени с целью предоставления важной информации, необходимых уведомлений и маркетинговых акций.",
    paragraph_third_information_collection: "Для улучшения работы с Приложением Поставщик услуг может потребовать от вас предоставить определенную личную информацию, включая, но не ограничиваясь, Трифонов Никита. Информация, которую запрашивает Поставщик услуг, будет сохранена и использована в соответствии с этой политикой конфиденциальности.",

    title_third_access: "Доступ Третьих Сторон",
    paragraph_third_access_first: "Только агрегированные, анонимные данные периодически передаются внешним сервисам, чтобы помочь Поставщику услуг улучшать Приложение и их услуги. Поставщик услуг может делиться вашей информацией с третьими сторонами способами, описанными в этом заявлении о конфиденциальности.",
    paragraph_third_access_second: "Обратите внимание, что Приложение использует сторонние сервисы, которые имеют свою собственную политику конфиденциальности в отношении обработки данных. Ниже приведены ссылки на политику конфиденциальности сторонних поставщиков услуг, используемых Приложением:",
    paragraph_third_access_third: "Поставщик услуг может раскрывать предоставленную пользователем и автоматически собранную информацию:",
    li_third_access_first: "как того требует закон, например, для соблюдения повестки в суд или аналогичного юридического процесса;",
    li_third_access_second: "когда они добросовестно полагают, что раскрытие необходимо для защиты их прав, обеспечения вашей безопасности или безопасности других лиц, расследования мошенничества или ответа на запрос правительства;",
    li_third_access_third: "с их доверенными поставщиками услуг, которые работают от их имени, не имеют самостоятельного использования информации, которую мы им раскрываем, и согласились соблюдать правила, изложенные в этом заявлении о конфиденциальности.",

    title_opt_out_rights: "Права на отказ",
    paragraph_opt_out_rights: "Вы можете легко прекратить сбор всей информации Приложением, удалив его. Вы можете воспользоваться стандартными процессами удаления, доступными на вашем мобильном устройстве, через магазин мобильных приложений или сеть.",

    title_data_retention: "Политика Xранения Данных",
    paragraph_data_retention: "Поставщик услуг будет хранить предоставленные пользователем данные до тех пор, пока вы используете Приложение, и в течение разумного времени после этого. Если вы хотите, чтобы они удалили предоставленные пользователем данные, которые вы предоставили через Приложение, пожалуйста, свяжитесь с ними по адресу clickedtop@gmail.com, и они ответят в разумные сроки.",

    title_children: "Дети",
    paragraph_children_first: "Поставщик услуг не использует Приложение для сознательного запроса данных или маркетинга в отношении детей младше 13 лет.",
    paragraph_children_second: "Приложение не предназначено для лиц младше 13 лет. Поставщик услуг сознательно не собирает персональные данные детей младше 13 лет. В случае, если Поставщик услуг обнаружит, что ребенок младше 13 лет предоставил личную информацию, Поставщик услуг немедленно удалит эти данные с их серверов. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок предоставил нам личную информацию, пожалуйста, свяжитесь с Поставщиком услуг (clickedtop@gmail.com), чтобы они могли принять необходимые меры.",

    title_security: "Безопасность",
    paragraph_security: "Поставщик услуг обеспокоен защитой конфиденциальности вашей информации. Поставщик услуг обеспечивает физические, электронные и процедурные меры защиты для охраны информации, которую он обрабатывает и хранит.",

    title_changes: "Изменения",
    paragraph_changes_first: "Настоящая Политика конфиденциальности может время от времени обновляться по любой причине. Поставщик услуг уведомит вас о любых изменениях в Политике конфиденциальности, обновив эту страницу с новой Политикой конфиденциальности. Рекомендуется регулярно просматривать эту Политику конфиденциальности на предмет изменений, так как продолжение использования считается согласием со всеми изменениями.",
    paragraph_changes_second: "Настоящая политика конфиденциальности вступает в силу с 13.06.2024.",

    title_consent: "Ваше Согласие",
    paragraph_consent: "Используя Приложение, вы соглашаетесь на обработку вашей информации, как это изложено в настоящей Политике конфиденциальности, сейчас и в случае её изменений.",

    title_contact_us: "Свяжитесь с нами",
    paragraph_contact_us: "Если у вас есть вопросы относительно конфиденциальности при использовании Приложения или вопросы о наших практиках, пожалуйста, свяжитесь с Поставщиком услуг по электронной почте: clickedtop@gmail.com.",
};