import imageLogo from '../../assets/logo192.png';

export const textModels = [
    { name: 'GPT', logo: imageLogo, desc: 'GPT is a transformer-based neural network model trained on extensive data to generate coherent text, support natural conversations, and perform various language tasks.' },
    { name: 'Claude', logo: imageLogo, desc: 'Claude is a transformer-based neural network model trained on extensive data to generate coherent text, support natural conversations, and perform various language tasks.' },
    { name: 'Gemini', logo: imageLogo, desc: 'Gemini is a transformer-based neural network model trained on extensive data to generate coherent text, support natural conversations, and perform various language tasks.' },
    { name: 'Mistral', logo: imageLogo, desc: 'Mistral is a transformer-based neural network model trained on extensive data to generate coherent text, support natural conversations, and perform various language tasks.' },
    { name: 'LLaMA', logo: imageLogo, desc: 'LLaMA is a transformer-based neural network model trained on extensive data to generate coherent text, support natural conversations, and perform various language tasks.' },
];

export const imageModels = [
    { name: 'Dalle', logo: imageLogo, desc: 'Dalle is a transformer-based neural network model trained on extensive data to generate coherent text, support natural conversations, and perform various language tasks.' },
    { name: 'Stable Diffusion', logo: imageLogo, desc: 'Stable Diffusion is a transformer-based neural network model trained on extensive data to generate coherent text, support natural conversations, and perform various language tasks.' },
];