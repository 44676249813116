export const COOKIE_POLICY_RU = {
    title_main: "Политика в отношении файлов cookie",
    description_main: "Настоящая политика в отношении файлов cookie применяется ко всем веб-сайтам Clicked AI и мобильному приложению Clicked AI («Сервисы Clicked AI»). Вы можете получить доступ и изменить свои настройки файлов cookie в любое время, нажав кнопку «настройки cookie» в разделе «Управление моими cookies» этой политики.",

    link_menu_first: "Что такое файлы cookie?",
    link_menu_second: "Веб-маяки / GIF",
    link_menu_third: "Сторонние файлы cookie",
    link_menu_fourth: "Какие файлы cookie мы используем и какую информацию они собирают?",
    link_menu_fifth: "Срок действия файлов cookie",
    link_menu_sixth: "Свяжитесь с нами",
    link_menu_seventh: "Связанные политики",

    title_what_are_cookies: "Что такое файлы cookie?",
    paragraph_what_are_cookies_first: "Clicked AI — это бренд компании Clicked Ltd (также именуемой 'мы' или 'нас'), которая использует файлы cookie в Сервисах Clicked AI. Файл cookie — это очень маленький текстовый документ, который часто содержит анонимный уникальный идентификатор. Файлы cookie создаются, когда ваш браузер загружает определенный веб-сайт. Веб-сайт отправляет информацию браузеру, который затем создает текстовый файл. Каждый раз, когда пользователь возвращается на тот же веб-сайт, браузер извлекает и отправляет этот файл на сервер веб-сайта. Узнайте больше об использовании файлов cookie на сайте",
    paragraph_what_are_cookies_second: "Мы также используем другие формы технологий (такие как веб-маяки и, в приложениях, наборы для разработки программного обеспечения (обычно называемые SDK)), которые служат аналогичной цели, что и файлы cookie, и позволяют нам отслеживать и улучшать наши платформы и электронные коммуникации. Когда мы говорим о файлах cookie в этой Политике в отношении файлов cookie, этот термин включает в себя аналогичные технологии. Если вы хотите получить дополнительную информацию о наших методах конфиденциальности, пожалуйста, ознакомьтесь с нашей",
    link_privacy_policy: "Политикой конфиденциальности",
    link_eu_uk_privacy_policy: "Политикой конфиденциальности для ЕС/Великобритании",
    span_what_are_cookies_or: "или",

    title_web_beacons: "Веб-маяки / GIF",
    paragraph_web_beacons: "Время от времени мы можем использовать программную технологию, называемую прозрачными GIF (также известными как веб-маяки), которая помогает нам лучше управлять контентом на наших Сервисах Clicked AI, информируя нас о том, какой контент является эффективным. Прозрачные GIF — это крошечные графические изображения с уникальным идентификатором, аналогичные по функции файлам cookie и используемые для отслеживания онлайн-активности пользователей. В отличие от файлов cookie, которые хранятся на жестком диске компьютера пользователя, прозрачные GIF незаметно встроены в наши Сервисы Clicked AI и имеют размер, сравнимый с точкой в конце этого предложения.",

    title_third_cookies: "Сторонние файлы cookie",
    paragraph_third_cookies: "Когда вы посещаете определенные разделы наших Сервисов Clicked AI, третьи стороны могут размещать файлы cookie в вашем браузере и/или использовать веб-маяки для сбора информации. Эта информация может включать такие данные, как ваше использование веб-сайта Clicked AI или мобильного приложения Clicked AI, информация об устройстве и браузере, а также данные о рекламе. Информация, генерируемая файлами cookie и веб-маяками о вашем использовании веб-сайта, передается этим третьим сторонам. Вам следует ознакомиться с политикой конфиденциальности и политикой в отношении файлов cookie этих сервисов, чтобы узнать, как третьи стороны используют файлы cookie.",

    title_main_cookies_collect: "Какие файлы cookie мы используем и какую информацию они собирают?",
    title_cookie_list: "Список файлов cookie",
    paragraph_cookie_list: "Файл cookie - это небольшой фрагмент данных (текстовый файл), который веб-сайт – при посещении пользователем – просит ваш браузер сохранить на вашем устройстве, чтобы запомнить информацию о вас, такую как предпочтительный язык или информация для входа. Эти файлы cookie устанавливаются нами и называются файлами cookie первой стороны. Мы также используем файлы cookie третьих сторон – это файлы cookie с домена, отличного от домена посещаемого вами веб-сайта – для наших рекламных и маркетинговых усилий. Более конкретно, мы используем файлы cookie и другие технологии отслеживания для следующих целей:",
    title_targeting_cookies: "Целевые файлы cookie",
    paragraph_targeting_cookies: "Эти файлы cookie могут быть установлены через наш сайт нашими рекламными партнерами. Эти компании могут использовать их для создания профиля ваших интересов и показа вам релевантных объявлений на других сайтах. Они не хранят непосредственно личную информацию, но основываются на уникальной идентификации вашего браузера и интернет-устройства. Если вы не разрешите использование этих файлов cookie, вы будете видеть менее целевую рекламу.",
    title_functional_cookies: "Функциональные файлы cookie",
    paragraph_functional_cookies: "Эти файлы cookie позволяют веб-сайту обеспечивать расширенную функциональность и персонализацию. Они могут быть установлены нами или сторонними поставщиками, чьи услуги мы добавили на наши страницы. Если вы не разрешите использование этих файлов cookie, некоторые или все эти услуги могут работать неправильно.",
    title_performance_cookies: "Файлы cookie для производительности",
    paragraph_performance_cookies: "Эти файлы cookie позволяют нам считать визиты и источники трафика, чтобы мы могли измерять и улучшать производительность нашего сайта. Они помогают нам узнать, какие страницы являются самыми популярными и наименее популярными, и видеть, как посетители перемещаются по сайту. Вся информация, которую собирают эти файлы cookie, агрегируется и является анонимной. Если вы не разрешите использование этих файлов cookie, мы не будем знать, когда вы посетили наш сайт, и не сможем отслеживать его производительность.",
    title_necessary_cookies: "Строго необходимые файлы cookie",
    paragraph_necessary_cookies: "Эти файлы cookie необходимы для функционирования веб-сайта и не могут быть отключены в наших системах. Обычно они устанавливаются только в ответ на ваши действия, которые являются запросом на предоставление услуг, например, настройка предпочтений конфиденциальности, вход в систему или заполнение форм. Вы можете настроить свой браузер на блокировку или уведомление о этих файлах cookie, но тогда некоторые части сайта не будут работать. Эти файлы cookie не хранят никакой персонально идентифицируемой информации.",

    title_cookie_lifespan: "Срок действия файлов cookie",
    paragraph_cookie_lifespan: "Файлы cookie могут оставаться в браузере пользователя до установленного времени истечения срока их действия и обычно действуют от нескольких секунд до нескольких лет. Срок действия файла cookie или его продолжительность обычно устанавливается доменом или третьей стороной, которая устанавливает файлы cookie. Существуют 2 типа файлов cookie, которые могут быть развернуты: сеансовые файлы cookie и постоянные файлы cookie.",
    title_session_cookies: "Сеансовые файлы cookie",
    paragraph_session_cookies: "Эти файлы cookie являются временными и истекают после закрытия вашего браузера (или после завершения сеанса).",
    title_persistent_cookies: "Постоянные файлы cookie",
    paragraph_persistent_cookies: "Эта категория включает все файлы cookie, которые остаются на вашем жестком диске, пока вы их не удалите или ваш браузер не сделает это, в зависимости от даты истечения срока действия файла cookie. Все постоянные файлы cookie имеют дату истечения срока, записанную в их коде, но их продолжительность может варьироваться. Они используются для запоминания пользователя и его предпочтений на веб-сайте.",

    title_contact: "Свяжитесь с нами",
    paragraph_contact: "Если у вас есть вопросы или опасения по поводу того, как мы обрабатываем ваши данные, пожалуйста, свяжитесь с нами по адресу clickedtop@gmail.com.",

    title_related_policies: "Связанные политики",
    paragraph_privacy_policy: "Политика конфиденциальности",
    paragraph_eu_uk_privacy_policy: "Политика конфиденциальности для ЕС/Великобритании",
};