import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { languages } from "./languages";

import styles from './LanguageDropdown.module.scss';

const CustomToggle = React.forwardRef(
    ({ children, onClick, isFooter }, ref) => (
        <a
            href="#main"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className={`${styles.language_container} ${isFooter ? styles.footer : ''}`}
        >
            {children}
        </a>
    )
);

const LanguageDropdown = ({ isFooter }) => {
    const { i18n } = useTranslation();
    // console.log('i18n.language', i18n.language);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    // console.log('selectedLanguage', selectedLanguage);

    useEffect(() => {
        setSelectedLanguage(i18n.language);
    }, [i18n.language]);

    const handleSelect = (code) => {
        console.log(code);
        setSelectedLanguage(code);
        i18n.changeLanguage(code.toLowerCase());
    };

    return (
        <Dropdown className={styles.dropdown}>
            <Dropdown.Toggle
                as={React.forwardRef((props, ref) => (
                    <CustomToggle ref={ref} {...props} isFooter={isFooter} />
                ))}
                id="dropdown-custom-components"
            >
                {selectedLanguage.toUpperCase()}
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.dropdown_menu}>
                {languages.map((lang) => (
                    <Dropdown.Item
                        key={lang.code}
                        onClick={() => handleSelect(lang.code)}
                        active={lang.code === selectedLanguage}
                        className={`${styles.dropdown_item} ${(lang.code ? lang.code.toLowerCase() : i18n.language) === selectedLanguage ? styles.active : ''}`}
                    >
                        {lang.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LanguageDropdown;
