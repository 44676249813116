export const FOOTER_EN = {
    title_information: "INFORMATION",
    title_support: "SUPPORT",
    title_help: "HELP",
    title_copyright: "MADE FROM CLICKED",

    link_privacy_policy: "Privacy policy",
    link_cookie_policy: "Cookie policy",
    link_terms_conditions: "Terms and conditions",
    link_prices_tariffs: "Prices and tariffs",
    link_ask_question: "Ask a question",
    link_feedback_suggestions: "Feedback and suggestions",
    link_report_violation: "Report a violation",
    link_questions_answers: "Questions and answers",
};