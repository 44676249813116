import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {

    const { t } = useTranslation("privacyPolicy");

    return (
        <Container fluid className={styles.container}>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h3>{t("title_main")}</h3>

                            <p>
                                {t("description_main")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_information_collection")}</h3>

                            <p>
                                {t("description_information_collection")}
                            </p>

                            <ul>
                                <li>
                                    {t("li_information_collection_first")}
                                </li>
                                <li>
                                    {t("li_information_collection_second")}
                                </li>
                                <li>{t("li_information_collection_third")}</li>
                                <li>
                                    {t("li_information_collection_fourth")}
                                </li>
                            </ul>

                            <p>
                                {t("paragraph_first_information_collection")}
                            </p>

                            <p>
                                {t("paragraph_second_information_collection")}
                            </p>

                            <p>
                                {t("paragraph_third_information_collection")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_third_access")}</h3>

                            <p>
                                {t("paragraph_third_access_first")}
                            </p>

                            <p>
                                {t("paragraph_third_access_second")}
                            </p>

                            <ul>
                                <li>
                                    <a
                                        href="https://www.google.com/policies/privacy/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Google Play Services
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://expo.io/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Expo
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.revenuecat.com/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        RevenueCat
                                    </a>
                                </li>
                            </ul>

                            <p>
                                {t("paragraph_third_access_third")}
                            </p>

                            <ul>
                                <li>
                                    {t("li_third_access_first")}
                                </li>
                                <li>
                                    {t("li_third_access_second")}
                                </li>
                                <li>
                                    {t("li_third_access_third")}
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h3>{t("title_opt_out_rights")}</h3>

                            <p>
                                {t("paragraph_opt_out_rights")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_data_retention")}</h3>

                            <p>
                                {t("paragraph_data_retention")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_children")}</h3>

                            <p>
                                {t("paragraph_children_first")}
                            </p>

                            <p>
                                {t("paragraph_children_second")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_security")}</h3>

                            <p>
                                {t("paragraph_security")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_changes")}</h3>

                            <p>
                                {t("paragraph_changes_first")}
                            </p>

                            <p>
                                {t("paragraph_changes_second")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_consent")}</h3>

                            <p>
                                {t("paragraph_consent")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_contact_us")}</h3>

                            <p>
                                {t("paragraph_contact_us")}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default PrivacyPolicy;
