export const COOKIE_MANAGE_RU = {
    title: "Центр настроек конфиденциальности",
    main_description:
        "Когда вы посещаете любой веб-сайт, он может сохранять или получать информацию в вашем браузере, в основном в виде файлов cookie. Эта информация может касаться вас, ваших предпочтений или вашего устройства и в основном используется для того, чтобы сайт работал так, как вы ожидаете. Обычно эта информация не идентифицирует вас напрямую, но может предоставить вам более персонализированный веб-опыт. Поскольку мы уважаем ваше право на конфиденциальность, вы можете выбрать, не разрешать использование некоторых типов файлов cookie. Нажмите на разные заголовки категорий, чтобы узнать больше и изменить наши настройки по умолчанию. Однако блокировка некоторых типов файлов cookie может повлиять на ваш опыт использования сайта и услуги, которые мы можем предложить.",
    link_information: "Больше информации",
    button_reject_all: "Отклонить все",
    button_allow_all: "Разрешить все",
    button_confirm: "Подтвердить мой выбор",
    title_category: "Управление настройками согласия",

    targeting_category: "Целевые Cookie",
    targeting_category_description:
        "",
    functional_category: "Функциональные Cookie",
    functional_category_description:
        "",
    performance_category: "Файлы cookie производительности",
    performance_category_description:
        "",
    necessary_category: "Строго необходимые Cookie",
    necessary_category_description:
        "",

    always_active: "Всегда Aктивны",
};
