import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import InputField from "../../UI/Input/InputField";
import { useTranslation } from "react-i18next";

import styles from "./AuthPage.module.scss";

const AuthPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSignUp, setIsSignUp] = useState(false);

    const { t } = useTranslation("auth");

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleOtherPageClick = () => {
        window.scrollTo(0, 0);
    };

    const toggleSignUp = () => {
        setIsSignUp((prev) => !prev);
    };

    return (
        <Container fluid className={styles.container}>
            <Container className={styles.container_content}>
                <p>
                    {isSignUp ? t("already_have_account") : t("dont_have_account")}{" "}
                    <span onClick={toggleSignUp} className={styles.span}>{isSignUp ? t("log_in") : t("sign_up_for_free")}</span>
                </p>

                <Form className={styles.modal_form}>
                    <InputField
                        controlId="formLoginEmail"
                        label="Email"
                        type="email"
                        placeholder={t("input_placeholder_email")}
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <InputField
                        controlId="formLoginPassword"
                        label={t("input_label_password")}
                        type="password"
                        placeholder={t("input_placeholder_password")}
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </Form>

                <Button variant="primary" className={styles.button}>
                    {isSignUp ? t("button_text_register") : t("button_text_login")}
                </Button>

                <p className={styles.approval}>
                    {t("signing_up_agree")}{" "}

                    <Link
                        to="/terms-and-conditions"
                        onClick={handleOtherPageClick}
                        className={styles.span}
                    >
                        {t("terms_and_conditions")}
                    </Link>{" "}

                    {t("and_the")}{" "}

                    <Link
                        to="/privacy-policy"
                        onClick={handleOtherPageClick}
                        className={styles.span}
                    >
                        {t("privacy_policy")}
                    </Link>
                    .
                </p>
            </Container>
        </Container>
    );
};

export default AuthPage;
