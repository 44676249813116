import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import CookieTable from "../../components/Cookie/CookieTable/CookieTable";
import {
    functionalCookies,
    performanceCookies,
    strictlyNecessaryCookies,
    targetingCookies,
} from "../../components/Cookie/CookieTable/cookieData";
import { useScrollContext } from "../../Provider/ScrollContext";
import { useTranslation } from "react-i18next";

import styles from "./CookiePolicy.module.scss";

const CookiePolicy = () => {
    const [showScroll, setShowScroll] = useState(false);
    const { setIsArrowVisible } = useScrollContext();

    const { t } = useTranslation("cookiePolicy");

    useEffect(() => {
        window.addEventListener("scroll", checkScrollTop);
        return () => {
            window.removeEventListener("scroll", checkScrollTop);
        };
        // eslint-disable-next-line
    }, [showScroll]);

   
    const checkScrollTop = () => {
        const newShowScroll = window.pageYOffset > 20;
        if (showScroll !== newShowScroll) {
            setShowScroll(newShowScroll);
            setIsArrowVisible(newShowScroll); // Обновляем контекст
        }
    };

    return (
        <Container fluid className={styles.container}>
            <Container>
                <Row>
                    <Col>
                        <h3>{t("title_main")}</h3>
                    </Col>
                </Row>

                <Row>
                    <Col md={3} sm={12} className={styles.menu}>
                        <a
                            href="#what-are-cookies"
                            className={styles.menu_item}
                        >
                            {t("link_menu_first")}
                        </a>
                        <a href="#web-beacons" className={styles.menu_item}>
                            {t("link_menu_second")}
                        </a>
                        <a
                            href="#third-party-cookies"
                            className={styles.menu_item}
                        >
                            {t("link_menu_third")}
                        </a>
                        <a
                            href="#what-cookies-we-use"
                            className={styles.menu_item}
                        >
                            {t("link_menu_fourth")}
                        </a>
                        <a href="#cookie-lifespan" className={styles.menu_item}>
                            {t("link_menu_fifth")}
                        </a>
                        <a href="#contact-us" className={styles.menu_item}>
                            {t("link_menu_sixth")}
                        </a>
                        <a href="#related-policies" className={styles.menu_item}>
                            {t("link_menu_seventh")}
                        </a>
                    </Col>

                    <Col md={9} className={styles.content}>
                        <p>
                            {t("description_main")}
                        </p>

                        <div
                            id="what-are-cookies"
                            className={styles.content_item}
                        >
                            <h4>{t("title_what_are_cookies")}</h4>

                            <p>
                                {t("paragraph_what_are_cookies_first")}{" "}
                                <a
                                    href="https://www.allaboutcookies.org/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.allaboutcookies.org
                                </a>
                                .
                            </p>

                            <p>
                                {t("paragraph_what_are_cookies_second")}{" "}
                                <Link to="/privacy-policy">{t("link_privacy_policy")}</Link>{" "}
                                {t("span_what_are_cookies_or")}{" "}
                                <Link to="/eu-uk-privacy-policy">
                                    {t("link_eu_uk_privacy_policy")}
                                </Link>
                                .
                            </p>
                        </div>

                        <div id="web-beacons" className={styles.content_item}>
                            <h4>{t("title_web_beacons")}</h4>

                            <p>
                                {t("paragraph_web_beacons")}
                            </p>
                        </div>

                        <div
                            id="third-party-cookies"
                            className={styles.content_item}
                        >
                            <h4>{t("title_third_cookies")}</h4>

                            <p>
                                {t("paragraph_third_cookies")}
                            </p>
                        </div>

                        <div
                            id="what-cookies-we-use"
                            className={styles.content_item}
                        >
                            <h4>
                                {t("title_main_cookies_collect")}
                            </h4>

                            <h5>{t("title_cookie_list")}</h5>

                            <p>
                                {t("paragraph_cookie_list")}
                            </p>

                            <h5>{t("title_targeting_cookies")}</h5>

                            <p>
                                {t("paragraph_targeting_cookies")}
                            </p>

                            <CookieTable cookieData={targetingCookies} />

                            <h5>{t("title_functional_cookies")}</h5>

                            <p>
                                {t("paragraph_functional_cookies")}
                            </p>

                            <CookieTable cookieData={functionalCookies} />

                            <h5>{t("title_performance_cookies")}</h5>

                            <p>
                                {t("paragraph_performance_cookies")}
                            </p>

                            <CookieTable cookieData={performanceCookies} />

                            <h5>{t("title_necessary_cookies")}</h5>

                            <p>
                                {t("paragraph_necessary_cookies")}
                            </p>

                            <CookieTable
                                cookieData={strictlyNecessaryCookies}
                            />
                        </div>

                        <div
                            id="cookie-lifespan"
                            className={styles.content_item}
                        >
                            <h4>{t("title_cookie_lifespan")}</h4>

                            <p>
                                {t("paragraph_cookie_lifespan")}
                            </p>

                            <h5>{t("title_session_cookies")}</h5>

                            <p>
                                {t("paragraph_session_cookies")}
                            </p>

                            <h5>{t("title_persistent_cookies")}</h5>

                            <p>
                                {t("paragraph_persistent_cookies")}
                            </p>
                        </div>

                        <div id="contact-us" className={styles.content_item}>
                            <h4>{t("title_contact")}</h4>

                            <p>
                                {t("paragraph_contact")}
                            </p>
                        </div>

                        <div
                            id="related-policies"
                            className={styles.content_item}
                        >
                            <h4>{t("title_related_policies")}</h4>

                            <Link to="/privacy-policy">{t("paragraph_privacy_policy")}</Link> {" "}
                            <Link to="/eu-uk-privacy-policy">
                                {t("paragraph_eu_uk_privacy_policy")}
                            </Link>
                        </div>
                    </Col>
                </Row>

                <FaArrowUp
                    className={styles.scrollTop}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    style={{ display: showScroll ? "flex" : "none" }}
                />
            </Container>
        </Container>
    );
};

export default CookiePolicy;
