export const slidesData = [
    {
        id: 1,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
    {
        id: 2,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
    {
        id: 3,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
    {
        id: 4,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
    {
        id: 5,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
    {
        id: 6,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
    {
        id: 7,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
    {
        id: 8,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
    {
        id: 9,
        text: "We thoroughly enjoyed the island being able to move about at our own pace. Cannot wait to come back and rent again!",
        name: "Sonya, NY",
    },
];
