export const questionsAndAnswersData = [
    {
        id: "1",
        title: "Что такое Clicked AI?",
        content:
            "Набор инструментов для написания текстов и генерации изображений с использованием ИИ, для создания SEO-оптимизированного контента, который увеличивает органический трафик на ваш сайт в Яндекс и Google. Размещая ключевые слова, внутренние и внешние ссылки в создаваемом вами контенте, вы можете увеличить трафик, продажи и доход в 10 раз.",
    },
    {
        id: "2",
        title: "Clicked AI бесплатен?",
        content:
            "Clicked AI, предлагает бесплатную версию из 20 генераций текста и 1 генерации изображения каждый месяц, чего достаточно, чтобы опробовать модели нейросетей. Наша служба поддержки клиентов может предоставить вам больше бесплатных слов, если вы представляете крупный бизнес, агентство или предприятие. Попробуйте бесплатную версию Clicked AI и испытайте на себе наши возможности написания контента с помощью искусственного интеллекта.",
    },
    {
        id: "3",
        title: "Какие языки поддерживает Clicked AI?",
        content:
            "На данный момент мы поддерживаем 5 языков. Вот список поддерживаемых на данный момент языков: Английский, Испанский, Французский, Немецкий, Русский. Если вы хотите, чтобы мы поддерживали любой другой язык, свяжитесь с нами по адресу clickedtop@gmail.com.",
    },
    {
        id: "4",
        title: "Кому принадлежит созданный контент?",
        content:
            "Принадлежит Вам. Вы сохраняете право собственности на все копии, созданные в вашей учетной записи. Вы можете использовать его как для личного, так и для коммерческого использования. Clicked AI не претендует на право собственности на копию в вашей учетной записи.",
    },
    {
        id: "5",
        title: "Содержимое, созданное с помощью Clicked AI, не содержит плагиата?",
        content:
            "Модели ИИ обучаются на миллиардах параметров. ИИ усваивает нюансы грамматики, правописания и стиля, чтобы создавать совершенно оригинальный контент. Следовательно, в 99% случаев создаваемый контент совершенно уникален и не содержит плагиата.",
    },
    {
        id: "6",
        title: "Кто может использовать Clicked AI?",
        content:
            "Clicked AI предназначен для всех, кто хочет создавать контент, будь то маркетологи, предприниматели, агентства или студенты. Вам не нужно быть профессиональным писателем, чтобы использовать Clicked AI. Созданный с помощью нейросетей SEO-оптимизированный текст используется тысячами профессиональных писателей, поскольку он помогает им получать новые идеи, экономит их время, повышает их производительность и, следовательно, приносит больший доход.",
    },
    {
        id: "7",
        title: "Предоставляет ли Clicked AI генератор изображений ИИ?",
        content:
            "Да, такой инструмент есть, он позволяет волшебным образом превращать текстовые подсказки в потрясающие изображения и рисунки. Просто введите текстовую подсказку и наблюдайте, как нейросеть создает прекрасное произведение искусства на основе ваших данных.",
    },
    {
        id: "8",
        title: "Что будет, если израсходуется лимит генераций раньше окончания месяца?",
        content: "Лимит генераций восстанавливается ежемесячно",
    },
    {
        id: "9",
        title: "Почему есть платные тарифы?",
        content: "В платных тарифах используются передовые модели нейросетей",
    },
    {
        id: "10",
        title: "Если у меня возникнут вопросы или сложности, мне помогут?",
        content:
            "Да, вы можете задать вопрос или описать техническую проблему на почту clickedtop@gmail.com с темой письма «Вопрос» или «Проблема» и мы ответим.",
    },
    {
        id: "11",
        title: "Как отключить подписку?",
        content: "здесь описание как отключить",
    },
    {
        id: "12",
        title: "Как удалить аккаунт?",
        content: "здесь описание как удалить",
    },
    {
        id: "13",
        title: "Как работает и обновляется подписка?",
        content: "здесь краткое описание",
    },
    {
        id: "14",
        title: "Не нашли ответа на свой вопрос?",
        content:
            "Вы можете задать вопрос или описать техническую проблему на почту clickedtop@gmail.com и мы ответим.",
    },
];
