import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

import styles from "./ModalComponent.module.scss";

const ModalComponent = ({
    show,
    onHide,
    title,
    children,
    footerContent,
    centered = true,
    size = "md",
}) => {
    return (
        <Modal
            contentClassName={styles.container}
            show={show}
            onHide={onHide}
            centered={centered}
            size={size}
            theme="light"
        >
            <Modal.Header className={styles.modal_header} closeButton>
                <Modal.Title className={styles.modal_title}>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.modal_body}>{children}</Modal.Body>
            <Modal.Footer className={styles.modal_footer}>
                {footerContent}
            </Modal.Footer>
        </Modal>
    );
};

ModalComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.node,
    footerContent: PropTypes.node,
    centered: PropTypes.bool,
    size: PropTypes.string,
};

export default ModalComponent;
