import React, { useState } from "react";
import { MdDone } from "react-icons/md";
import { Button, Row } from "react-bootstrap";
import ModalComponent from "../../../UI/Modal/ModalComponent";
import ModalDownloadContent from "../../ModalDownloadContent/ModalDownloadContent";
import { useTranslation } from "react-i18next";

import styles from "./TariffPlanItem.module.scss";
import StoreButtons from "../../StoreButtons/StoreButtons";

const TariffPlanItem = ({
    title,
    price,
    buttonText,
    tariffType,
    features,
    description,
}) => {
    const [modalShow, setModalShow] = useState(false);

    const { t } = useTranslation("pricing");

    const handleGetStartedClick = () => {
        setModalShow(true);
    };

    const handleClose = () => {
        setModalShow(false);
    };

    return (
        <>
            <div className={`${styles.tariff_item} ${styles[tariffType]}`}>
                <div
                    className={`${styles.tariff_header} ${
                        styles[`${tariffType}_header`]
                    }`}
                >
                    <div className={styles.tariff_header_content}>
                        <h3 className={styles.tariff_item_title}>{title}</h3>

                        <p className={styles.tariff_item_description}>
                            {t(description)}
                        </p>

                        <div className={styles.tariff_item_price}>
                            $ <span className={styles.price}>{price}</span>{" "}
                            /month
                        </div>
                    </div>

                    <Button
                        onClick={handleGetStartedClick}
                        className={styles.button}
                    >
                        {t(buttonText)}
                    </Button>
                </div>

                <div className={styles.models_container}>
                    {features.map((feature, index) => (
                        <div key={index} className={styles.item_container}>
                            <div className={styles.icon}>
                                <MdDone />
                            </div>
                            <div className={styles.text_container}>
                                <div>
                                    <span className={styles.total}>
                                        {feature.total}
                                    </span>{" "}
                                    {t(feature.description)}
                                </div>
                                <div className={styles.model}>
                                    {t(feature.models)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <ModalComponent
                show={modalShow}
                onHide={handleClose}
                title={"Download App"}
                footerContent={<Button onClick={handleClose}>Close</Button>}
            >
                <ModalDownloadContent />
                
            </ModalComponent>
        </>
    );
};

export default TariffPlanItem;
