import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { FaPlus, FaMinus } from "react-icons/fa6";

import styles from "./AccordionItems.module.scss";

const CustomToggle = ({
    children,
    eventKey,
    alwaysActive,
    isOpen,
    handleClick,
    switchChecked,
    handleSwitchChange
}) => {
    const decoratedOnClick = useAccordionButton(eventKey, handleClick);

    const { t } = useTranslation("cookieManage");

    return (
        <div className={styles.custom_toggle_container}>
            <button
                type="button"
                className={styles.custom_toggle}
                onClick={decoratedOnClick}
            >
                <div className={styles.toggle_content}>
                    {isOpen ? <FaMinus /> : <FaPlus />}
                    {children}
                </div>
            </button>

            {alwaysActive ? (
                <span className={styles.always_active}>{t("always_active")}</span>
            ) : (
                <Form className={styles.form}>
                    <Form.Check
                        type="switch"
                        // id={`custom-switch-${eventKey}`}
                        className={styles.form_switch}
                        id={children.split(' ')[0]}
                        checked={switchChecked}
                        onChange={handleSwitchChange}
                    />
                </Form>
            )}
        </div>
    );
};

const AccordionItems = ({ cookiePreferences, handleSwitchChange }) => {
    const [openKey, setOpenKey] = useState(null);

    const { t } = useTranslation("cookieManage");

    const handleToggle = (eventKey) => {
        setOpenKey((prevKey) => (prevKey === eventKey ? null : eventKey));
    };

    return (
        <Accordion>
            <Card className={styles.accordion_item}>
                <Card.Header>
                    <CustomToggle
                        eventKey="0"
                        isOpen={openKey === "0"}
                        handleClick={() => handleToggle("0")}
                        switchChecked={cookiePreferences.Targeting}
                        handleSwitchChange={handleSwitchChange}
                    >
                        {t("targeting_category")}
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body className={styles.item_body}>
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        directly personal information, but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        targeted advertising.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card className={styles.accordion_item}>
                <Card.Header>
                    <CustomToggle
                        eventKey="1"
                        isOpen={openKey === "1"}
                        handleClick={() => handleToggle("1")}
                        switchChecked={cookiePreferences.Functional}
                        handleSwitchChange={handleSwitchChange}
                    >
                        {t("functional_category")}
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body className={styles.item_body}>
                        These cookies enable the website to provide enhanced
                        functionality and personalisation. They may be set by us
                        or by third party providers whose services we have added
                        to our pages. If you do not allow these cookies then
                        some or all of these services may not function properly.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card className={styles.accordion_item}>
                <Card.Header>
                    <CustomToggle
                        eventKey="2"
                        isOpen={openKey === "2"}
                        handleClick={() => handleToggle("2")}
                        switchChecked={cookiePreferences.Performance}
                        handleSwitchChange={handleSwitchChange}
                    >
                        {t("performance_category")}
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body className={styles.item_body}>
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card className={styles.accordion_item}>
                <Card.Header>
                    <CustomToggle
                        eventKey="3"
                        isOpen={openKey === "3"}
                        handleClick={() => handleToggle("3")}
                        alwaysActive={true}
                        switchChecked={cookiePreferences.Necessary}
                        handleSwitchChange={handleSwitchChange}
                    >
                        {t("necessary_category")}
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body className={styles.item_body}>
                        These cookies are necessary for the website to function
                        and cannot be switched off in our systems. They are
                        usually only set in response to actions made by you
                        which amount to a request for services, such as setting
                        your privacy preferences, logging in or filling in
                        forms. You can set your browser to block or alert you
                        about these cookies, but some parts of the site will not
                        then work. These cookies do not store any personally
                        identifiable information.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default AccordionItems;
