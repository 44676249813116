import React from "react";

import styles from "./CarouselImage.module.scss";

const CarouselImage = ({ text }) => {
    const imageMap = {
        "First slide": require("../../../../assets/slide1.png"),
        "Second slide": require("../../../../assets/slide2.png"),
        "Third slide": require("../../../../assets/slide3.png"),
    };

    const imageUrl = imageMap[text];

    return (
        <div className={styles.carousel_image}>
            <img
                src={imageUrl}
                alt={text}
            />
        </div>
    );
};

export default CarouselImage;
