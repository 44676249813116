export const targetingCookies = [
    { subgroup: "", cookies: "_fbp", type: "First Party" },
    { subgroup: "facebook.com", cookies: "fr", type: "Third Party" },
    { subgroup: "bing.com", cookies: "MUID", type: "Third Party" },
    {
        subgroup: "youtube.com",
        cookies: "VISITOR_INFO1_LIVE, VISITOR_PRIVACY_METADATA, YSC",
        type: "Third Party",
    },
    {
        subgroup: "srv.stackadapt.com",
        cookies: "sa-user-id, sa-user-id-v2, sa-user-id-v3",
        type: "Third Party",
    },
    {
        subgroup: "tags.srv.stackadapt.com",
        cookies: "sa-user-id-v3",
        type: "Third Party",
    },
    {
        subgroup: "www.youtube.com",
        cookies: "TESTCOOKIESENABLED",
        type: "Third Party",
    },
    {
        subgroup: "qvdt3feo.com",
        cookies: "sa-user-id, sa-user-id-v2, sa-user-id-v3",
        type: "Third Party",
    },
    {
        subgroup: "vimeo.com",
        cookies: "_cfuvid, cf_clearance",
        type: "Third Party",
    },
    { subgroup: "www.google.com", cookies: "_GRECAPTCHA", type: "Third Party" },
    { subgroup: "tiktok.com", cookies: "_ttp", type: "Third Party" },
    { subgroup: "c.bing.com", cookies: "MR, SRM_B", type: "Third Party" },
];

export const functionalCookies = [
    {
        subgroup: "",
        cookies:
            "__insp_pad , __insp_sid , __insp_uid , __mp_opt_in_out_36457910ceb909c0a888c62e1379b955 , _clck , _tgidts , _tglksd , _tgpc , _tgsid , _tgt_ , _tguatd , _tt_enable_cookie , _ttp , _wt.mode-2384149 , _wt.user-2384149 , sa-user-id , sa-user-id-v2 , sa-user-id-v3",
        type: "First Party",
    },
    { subgroup: "vimeo.com", cookies: "__cf_bm", type: "Third Party" },
];

export const performanceCookies = [
    {
        subgroup: "",
        cookies:
            "__insp_dct , __insp_nv , __insp_slim , __insp_targlpt , __insp_targlpu , __insp_wid , _clsk , _ga , _ga_xxxxxxxxxx , _gclxxxx",
        type: "First Party",
    },
    { subgroup: "nr-data.net", cookies: "JSESSIONID", type: "Third Party" },
    { subgroup: "www.clarity.ms", cookies: "CLID", type: "Third Party" },
    { subgroup: "clarity.ms", cookies: "MUID", type: "Third Party" },
    {
        subgroup: "c.clarity.ms",
        cookies: "ANONCHK, MR, SM",
        type: "Third Party",
    },
];

export const strictlyNecessaryCookies = [
    { subgroup: "", cookies: "incap_ses_ , nlbi_2890650 , OptanonAlertBoxClosed , OptanonConsent , visid_incap_ , wordpress_test_cookie", type: "First Party" },
    { subgroup: "vimeo.com", cookies: "vuid", type: "Third Party" },
    { subgroup: "trafficguard.ai", cookies: "geid, geid-legacy", type: "Third Party" },
    { subgroup: "onetrust.com", cookies: "_cfuvid", type: "Third Party" },
]