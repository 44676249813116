export const AUTH_RU = {
    button_text_login: "Войти",
    button_text_register: "Регистрация",

    title_modal_login: "Войти",
    title_modal_register: "Регистрация",

    input_label_password: "Пароль",
    input_placeholder_password: "Введите ваш пароль",
    input_label_email: "Email",
    input_placeholder_email: "Введите ваш email",

    already_have_account: "Уже есть аккаунт?",
    dont_have_account: "Нет аккаунта?",

    log_in: "Войти",
    sign_up_for_free: "Зарегистрироваться бесплатно",
    sign_up: "Зарегистрироваться",

    signing_up_agree: "Регистрируясь, вы соглашаетесь с",
    terms_and_conditions: "Условиями и Положениями",
    privacy_policy: "Политикой Конфиденциальности",
    and_the: "и",
};