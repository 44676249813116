export const COOKIE_POLICY_EN = {
    title_main: "Cookie Policy",
    description_main: "This cookie policy applies to all Clicked AI websites, and the Clicked AI mobile application (“Clicked AI Services”). You may access and change your cookie preferences at any time by clicking the ‘cookie settings’ button in the Manage my cookies section of this policy.",

    link_menu_first: "What are cookies?",
    link_menu_second: "Web Beacons / Gifs",
    link_menu_third: "Third Party Cookies",
    link_menu_fourth: "What cookies do we use and what information do they collect?",
    link_menu_fifth: "Cookie Lifespan",
    link_menu_sixth: "Contact us",
    link_menu_seventh: "Related Policies",

    title_what_are_cookies: "What are cookies?",
    paragraph_what_are_cookies_first: "Clicked AI is a brand of Clicked Ltd (also referred to as the “we” or “us“) that uses cookies on the Clicked AI Services. A cookie is a very small text document, which often includes an anonymous unique identifier. Cookies are created when your browser loads a particular website. The website sends information to the browser which then creates a text file. Every time the user goes back to the same website, the browser retrieves and sends this file to the website’s server. Find out more about the use of cookies on",
    paragraph_what_are_cookies_second: "We also use other forms of technology (such as web beacons and, in apps, software development kits (usually referred to as SDKs)) which serve a similar purpose to cookies and which allow us to monitor and improve our platforms and email communications. When we talk about cookies in this Cookie Policy, this term includes these similar technologies. If you would like more information about our privacy practices, please consult our",
    link_privacy_policy: "Privacy Policy",
    link_eu_uk_privacy_policy: "EU/UK Privacy Policy",
    span_what_are_cookies_or: "or",

    title_web_beacons: "Web Beacons / Gifs",
    paragraph_web_beacons: "We may from time to time employ a software technology called clear gifs (a.k.a. Web Beacons), that help us better manage content on our Clicked AI Services by informing us what content is effective. Clear gifs are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of users. In contrast to cookies, which are stored on a user’s computer hard drive, clear gifs are embedded invisibly on our Clicked AI Services and are about the size of the period at the end of this sentence.",

    title_third_cookies: "Third Party Cookies",
    paragraph_third_cookies: "When you visit certain sections of our Clicked AI Services, third parties may place cookies on your computer’s browser and/or make use of web beacons to collect information. This information may include things such as your use of the Clicked AI website or Clicked AI mobile application, device and browser information and ad data. The information generated by the cookies and web beacons about your use of the website is transmitted to those third parties. You should review the privacy and cookie policies of these services to find out how these third parties use cookies.",

    title_main_cookies_collect: "What cookies do we use and what information do they collect?",
    title_cookie_list: "Cookie List",
    paragraph_cookie_list: "A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store on your device in order to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. We also use third-party cookies – which are cookies from a domain different than the domain of the website you are visiting – for our advertising and marketing efforts. More specifically, we use cookies and other tracking technologies for the following purposes:",
    title_targeting_cookies: "Targeting Cookies",
    paragraph_targeting_cookies: "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
    title_functional_cookies: "Functional Cookies",
    paragraph_functional_cookies: "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.",
    title_performance_cookies: "Performance Cookies",
    paragraph_performance_cookies: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.",
    title_necessary_cookies: "Strictly Necessary Cookies",
    paragraph_necessary_cookies: "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",

    title_cookie_lifespan: "Cookie Lifespan",
    paragraph_cookie_lifespan: "Cookies can last on a user’s browser till the time it is set to expire and typically last from seconds to years. The cookie expiration or cookie duration is generally set by the domain or third party that drops the cookies. There are 2 types of cookies that may be deployed: Sessions cookies and persistent cookies.",
    title_session_cookies: "Session cookies",
    paragraph_session_cookies: "These cookies are temporary and expire once you close your browser (or once your session ends).",
    title_persistent_cookies: "Persistent cookies",
    paragraph_persistent_cookies: "This category encompasses all cookies that remain on your hard drive until you erase them or your browser does, depending on the cookie’s expiration date. All persistent cookies have an expiration date written into their code, but their duration can vary. They are used to remember a user and their preferences on a website.",

    title_contact: "Contact us",
    paragraph_contact: "If you have any questions or concerns about how we process your data, please contact us at clickedtop@gmail.com.",

    title_related_policies: "Related Policies",
    paragraph_privacy_policy: "Privacy Policy",
    paragraph_eu_uk_privacy_policy: "EU/UK Privacy Policy",
};