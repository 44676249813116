import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './InputField.module.scss'; // Стили для всех форм

const InputField = ({ controlId, label, type = 'text', placeholder, value, onChange }) => {
    return (
        <Form.Group controlId={controlId} className={styles.form_group}>
            <Form.Label className={styles.input_label}>{label}</Form.Label>
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={styles.input_control}
            />
        </Form.Group>
    );
};

InputField.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired
};

export default InputField;
