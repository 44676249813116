import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./Menu.module.scss";

function Menu() {

    const { t } = useTranslation("navbar");

    return (
        <Nav className={`${styles.container} `}>
            <Nav.Link href="#main">{t("link_main")}</Nav.Link>
            <Nav.Link href="#content">{t("link_content")}</Nav.Link>
            <Nav.Link href="#pricing">{t("link_pricing")}</Nav.Link>
            <Nav.Link href="#main">{t("link_advantages")}</Nav.Link>
        </Nav>
    );
}

export default Menu;
