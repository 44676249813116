import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Offcanvas, Button } from "react-bootstrap";
import AccordionItems from "./AccordionItems/AccordionItems";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";

import styles from "./CookieManage.module.scss";

const CookieManage = ({ show, handleClose, handleSavePreferences, initialPreferences }) => {
    const [preferences, setPreferences] = useState(initialPreferences);

    const { t } = useTranslation("cookieManage");

    useEffect(() => {
        const storedPreferences = Cookies.get('cookiePreferences');
        if (storedPreferences) {
            const parsedPreferences = JSON.parse(storedPreferences);
            setPreferences(parsedPreferences.preferences);
        }
    }, [initialPreferences]);

    const handleAllowAll = () => {
        const newPreferences = {
            Targeting: true,
            Functional: true,
            Performance: true,
            Necessary: true
        };
        handleSavePreferences(newPreferences, true);
    };

    const handleRejectAll = () => {
        const newPreferences = {
            Targeting: false,
            Functional: false,
            Performance: false,
            Necessary: true 
        };
        handleSavePreferences(newPreferences, false);
    };

    const handleConfirmChoices = () => {
        handleSavePreferences(preferences, Object.values(preferences).some(pref => pref));
    };

    const handleSwitchChange = (event) => {
        const { id, checked } = event.target;
        setPreferences(prev => ({ ...prev, [id]: checked }));
    };

    const handleOtherPageClick = () => {
        window.scrollTo(0, 0);
        handleClose();
    };

    return (
        <Offcanvas
            variant="dark"
            show={show}
            onHide={handleClose}
            className={styles.container}
        >
            <Offcanvas.Header closeButton data-bs-theme="dark">
                {/* <Offcanvas.Title>Menu</Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body className={styles.container_body}>
                <h3>{t("title")}</h3>

                <p className={styles.text}>
                    {t("main_description")}
                </p>

                <Link to="/cookie-policy" onClick={handleOtherPageClick} className={styles.link}>
                    {t("link_information")}
                </Link>

                .<Button onClick={handleAllowAll} className={styles.button_allow}>{t("button_allow_all")}</Button>

                <h3>{t("title_category")}</h3>

                <AccordionItems 
                    cookiePreferences={preferences}
                    handleSwitchChange={handleSwitchChange}
                />

                <div className={styles.buttons_actions}>
                    <Button onClick={handleRejectAll} className={styles.button_reject}>{t("button_reject_all")}</Button>
                    <Button onClick={handleConfirmChoices}>{t("button_confirm")}</Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CookieManage;
