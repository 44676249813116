export const TERMS_AND_CONDITIONS_RU = {
    title_main: "Условия и положения",
    paragraph_main_first: "Эти условия использования применяются к приложению Clicked Ai (далее именуемому 'Приложение') для мобильных устройств, которое было создано компанией Clicked (далее именуемой 'Поставщик услуг') в качестве бесплатного сервиса.",
    paragraph_main_second: "Скачивая или используя Приложение, вы автоматически соглашаетесь с приведенными ниже условиями. Настоятельно рекомендуется внимательно прочитать и понять эти условия перед использованием Приложения. Несанкционированное копирование, модификация Приложения, любой его части или наших товарных знаков строго запрещены. Любые попытки извлечения исходного кода Приложения, перевода Приложения на другие языки или создания производных версий не допускаются. Все товарные знаки, авторские права, права на базы данных и другие права интеллектуальной собственности, связанные с Приложением, остаются собственностью Поставщика услуг.",
    paragraph_main_third: "Поставщик услуг стремится сделать Приложение максимально полезным и эффективным. Поэтому он оставляет за собой право изменять Приложение или взимать плату за свои услуги в любое время и по любой причине. Поставщик услуг гарантирует, что любые взимаемые за Приложение или его услуги платежи будут четко сообщены вам.",
    paragraph_main_fourth: "Приложение сохраняет и обрабатывает персональные данные, которые вы предоставили Поставщику услуг, для предоставления Услуги. Вы несете ответственность за обеспечение безопасности вашего телефона и доступа к Приложению. Поставщик услуг настоятельно не рекомендует выполнять джейлбрейк или рутинг вашего телефона, что включает удаление программных ограничений и лимитов, установленных официальной операционной системой вашего устройства. Такие действия могут подвергнуть ваш телефон воздействию вредоносных программ, вирусов, вредоносных программ, скомпрометировать функции безопасности вашего телефона и могут привести к некорректной работе Приложения или его полной неработоспособности. Обратите внимание, что Приложение использует сторонние сервисы, которые имеют свои собственные Условия использования. Ниже приведены ссылки на Условия использования сторонних поставщиков услуг, используемых Приложением:",
    paragraph_main_fifth: "Пожалуйста, обратите внимание, что Поставщик услуг не несет ответственности за определенные аспекты. Некоторые функции Приложения требуют активного интернет-соединения, которое может быть предоставлено через Wi-Fi или вашим мобильным оператором. Поставщик услуг не несет ответственности, если Приложение не функционирует в полной мере из-за отсутствия доступа к Wi-Fi или если у вас исчерпан лимит данных.",
    paragraph_main_sixth: "Если вы используете приложение вне зоны Wi-Fi, пожалуйста, имейте в виду, что условия соглашения вашего мобильного оператора по-прежнему применяются. Следовательно, ваш мобильный оператор может взимать плату за использование данных при подключении к приложению, а также могут возникнуть другие сторонние расходы. Используя приложение, вы принимаете на себя ответственность за такие расходы, включая плату за роуминг данных, если вы используете приложение за пределами вашей домашней территории (то есть региона или страны) без отключения роуминга данных. Если вы не являетесь плательщиком счета за устройство, на котором используется приложение, предполагается, что вы получили разрешение от плательщика счета.",
    paragraph_main_seventh: "Аналогично, Поставщик услуг не всегда может нести ответственность за ваше использование приложения. Например, вы несете ответственность за то, чтобы ваше устройство оставалось заряженным. Если ваше устройство разрядится, и вы не сможете получить доступ к Услуге, Поставщик услуг не несет за это ответственности.",
    paragraph_main_eighth: "Что касается ответственности Поставщика услуг за ваше использование приложения, важно отметить, что, несмотря на их усилия по обеспечению своевременного обновления и точности информации, они зависят от сторонних организаций, предоставляющих им информацию для дальнейшего предоставления вам. Поставщик услуг не несет ответственности за любые убытки, прямые или косвенные, которые вы понесли в результате полного доверия этой функциональности приложения.",
    paragraph_main_ninth: "Поставщик услуг может захотеть обновить приложение в какой-то момент. В настоящее время приложение доступно в соответствии с требованиями операционной системы (и для любых дополнительных систем, для которых они решат расширить доступность приложения), которые могут изменяться, и вам потребуется загружать обновления, если вы хотите продолжать использовать приложение. Поставщик услуг не гарантирует, что всегда будет обновлять приложение так, чтобы оно было актуально для вас и/или совместимо с конкретной версией операционной системы, установленной на вашем устройстве. Однако вы соглашаетесь всегда принимать обновления приложения, когда они вам предлагаются. Поставщик услуг также может прекратить предоставление приложения и может прекратить его использование в любое время без предварительного уведомления. Если вам не сообщат иное, после любого прекращения (a) права и лицензии, предоставленные вам по этим условиям, прекратятся; (b) вы должны прекратить использование приложения и (если необходимо) удалить его с вашего устройства.",

    title_changes_terms_conditions: "Изменения в настоящих Условиях использования",
    paragraph_changes_terms_conditions_first: "Поставщик услуг может периодически обновлять свои Условия использования. Поэтому вам рекомендуется регулярно просматривать эту страницу на предмет изменений. Поставщик услуг уведомит вас о любых изменениях, разместив новые Условия использования на этой странице.",
    paragraph_changes_terms_conditions_second: "Настоящие условия использования вступают в силу с 13 июня 2024 года.",

    title_contact_us: "Свяжитесь с нами",
    paragraph_contact_us: "Если у вас есть вопросы или предложения по поводу Условий использования, пожалуйста, свяжитесь с Поставщиком услуг по адресу clickedtop@gmail.com.",
};