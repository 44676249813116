import React, { useState } from "react";
import { Alert, Button, Container, Image } from "react-bootstrap";
import { InputField } from "../../../UI";

import { useTranslation } from "react-i18next";
import styles from "./ProfileRemove.module.scss";

function ProfileRemove() {
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [uuid, setUuid] = useState("");
    const [alert, setAlert] = useState(false);

    const removeProfileHandler = () => {
        setAlert(true);
        setEmail("");
        setUuid("");
    };

    return (
        <Container fluid>
            <Container className={styles.container} style={{ maxWidth: "600px", marginTop: "100px" }}>
                <div style={{display: "flex", gap: "10px", alignItems: "center" }}>
                    <Image src="/logo512.png" style={{ width: "50px" }} /> 
                    <p style={{ fontSize: "21px", fontWeight: "600", }}>Clicked Ai & Smart Assistant</p>
                </div>
                
            </Container>

            <Container className={styles.container}
            style={{ maxWidth: "600px", marginTop: "20px" }}>
                <div>You can delete your profile in the application in the <strong>Profile > Settings</strong> section</div>
                <div>Or use the form</div>
            </Container>
            
            <Container
                className={styles.container}
                style={{ maxWidth: "600px", margin: "50px auto" }}
            >
                <h3 style={{ marginBottom: "20px" }}>Profile Remove</h3>
                {alert && (
                    <Alert variant="danger" style={{ marginBottom: "20px" }}>
                        {" "}
                        We have received your request for account deletion{" "}
                    </Alert>
                )}
                <InputField
                    label={"Email"}
                    placeholder={"Email"}
                    type={"email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <InputField
                    label={"UUID"}
                    placeholder={"UUID"}
                    type={"text"}
                    value={uuid}
                    onChange={(e) => setUuid(e.target.value)}
                />
                <Button
                    onClick={removeProfileHandler}
                    disabled={!(email && uuid)}
                >
                    {" "}
                    Remove{" "}
                </Button>
            </Container>
        </Container>
    );
}

export default ProfileRemove;
