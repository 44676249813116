import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { InputField, ModalComponent } from "../../../UI";

import styles from "./Login.module.scss";

const LoginModal = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { t } = useTranslation("auth");

    const handleShowLogin = () => setShowLogin(true);
    const handleCloseLogin = () => setShowLogin(false);

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const loginForm = (
        <Form className={styles.modal_form}>
            <InputField
                controlId="formLoginEmail"
                label="Email"
                type="email"
                placeholder={t("input_placeholder_email")}
                value={email}
                onChange={handleEmailChange}
            />
            <InputField
                controlId="formLoginPassword"
                label={t("input_label_password")}
                type="password"
                placeholder={t("input_placeholder_password")}
                value={password}
                onChange={handlePasswordChange}
            />
        </Form>
    );

    const loginButton = (
        <Button variant="primary" onClick={handleCloseLogin}>
            {t("button_text_login")}
        </Button>
    );

    return (
        <>
            <Button  variant="link" className={styles.login_button} onClick={handleShowLogin}>
                {t("button_text_login")}
            </Button>
            <ModalComponent
                show={showLogin}
                onHide={handleCloseLogin}
                title={t("title_modal_login")}
                children={loginForm}
                footerContent={loginButton}
            />
        </>
    );
};

export default LoginModal;
