export const tariffPlans = [
    {
        title: "free",
        price: "0",
        buttonText: "button_get_started",
        tariffType: "free",
        description: "free_description",
        features: [
            {
                total: "20",
                description: "features_description_standard",
                models: "features_description_standard_model",
            },
            {
                total: "1",
                description: "features_description_image",
                models: "features_description_image_model",
            },
            { total: "", description: "features_description_support_free", models: "" },
        ],
    },
    {
        title: "pro",
        price: "8.9",
        buttonText: "button_get_started",
        tariffType: "pro",
        description: "pro_description",
        features: [
            {
                total: "5000",
                description: "features_description_standard",
                models: "features_description_standard_model",
            },
            {
                total: "200",
                description: "features_description_advanced",
                models: "features_description_advanced_model",
            },
            {
                total: "100",
                description: "features_description_image",
                models: "features_description_image_model",
            },
            { total: "", description: "features_description_support_pro", models: "" },
        ],
    },
    {
        title: "pro+",
        price: "18.9",
        buttonText: "button_get_started",
        tariffType: "pro_plus",
        description: "pro_plus_description",
        features: [
            {
                total: "10 000",
                description: "features_description_standard",
                models: "features_description_standard_model",
            },
            {
                total: "500",
                description: "features_description_advanced",
                models: "features_description_advanced_model",
            },
            {
                total: "250",
                description: "features_description_image",
                models: "features_description_image_model",
            },
            { total: "", description: "features_description_support_pro_plus", models: "" },
        ],
    },
];
