export const MAIN_RU = {
    title_span1: "и другие популярные",
    title_span2: "ИИ",
    title_span3: "в одном месте",
    description_title:
        "Поговорите с GPT chat, GPT-4o, Claude 3, DALLE 3 и другими",
    get_started: "Начать БЕСПЛАТНО",
    download_button_apple: "Скачать на",
    download_button_google: "Скачать на",
    scroll_down: "прокрутите вниз и увидете больше",
    description_text_block_first:
        "Clicked AI - это набор инструментов с алгоритмами, которые работают аналогично человеческому мозгу. Эти инструменты упрощают выполнение различных задач, полезных как владельцам малого бизнеса, так и обычным людям.",
    description_text_block_second: "Модели в Clicked AI помогут вам решать задачи, с которыми мы часто сталкиваемся в интернете: отвечать на вопросы; писать, исправлять и улучшать тексты; рисовать картинки; переводить тексты и видео с разных языков.",
};
