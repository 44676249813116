import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./EuUkPrivacyPolicy.module.scss";

const EuUkPrivacyPolicy = () => {
    return (
        <Container fluid className={styles.container}>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h3>EU/UK Privacy Policy</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default EuUkPrivacyPolicy;
