import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

export const useScrollContext = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
    const [isArrowVisible, setIsArrowVisible] = useState(false);
    return (
        <ScrollContext.Provider value={{ isArrowVisible, setIsArrowVisible }}>
            {children}
        </ScrollContext.Provider>
    );
};
