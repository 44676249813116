import React from "react";

import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./ModelCard.module.scss";

const ModelCard = ({ name, logo, desc }) => {
    const popover = (
        <Popover
            id="popover-basic"
            className={styles.popover}
            
        >
            <Popover.Body className={styles.body}>{desc}</Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger={['hover', 'focus']} delay={{ show: 250, hide: 300 }} placement="top" overlay={popover}>
            <div className={styles.generate_text_models_item}>
                <img src={logo} alt={`${name} logo`} />

                <p className={styles.model_item}>{name}</p>
            </div>
        </OverlayTrigger>
    );
};

export default ModelCard;
