import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Content from "../../components/Content/Content";
import Main from "../../components/Main/Main";
import Pricing from "../../components/Pricing/Pricing";
import Reviews from "../../components/Reviews/Reviews";

const MainContent = () => {
    const mainRef = useRef(null);
    const pricingRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash === "#pricing" && pricingRef.current) {
            pricingRef.current.scrollIntoView({ behavior: "smooth" });
        }

        if (location.hash === "#main" && mainRef.current) {
            mainRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [location]);

    return (
        <>
            <div className="main" id="main" ref={mainRef}>
                <Main />
            </div>

            <div className="content" id="content">
                <Content />
            </div>
            
            <div id="pricing" ref={pricingRef}>
                <Pricing />
            </div>

            <div className="reviews" id="reviews">
                <Reviews />
            </div>
        </>
    );
};

export default MainContent;
