import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import App from './App';
import './locale/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// <a href="https://ru.freepik.com/free-psd/full-screen-smartphone-mockup-design_3576506.htm#fromView=author&page=1&position=0&uuid=388cd74a-97e1-4e02-9b7f-2fa4f395e7cf">Изображение от rawpixel.com</a> на Freepik