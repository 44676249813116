import React from "react";
import { Button } from "react-bootstrap";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import appstore from "../../assets/appstore.svg";
import playstore from "../../assets/playstore.svg";

import styles from "./StoreButtons.module.scss";

function StoreButtons({wrap}) {
    return (
        <div style={wrap} className={styles.button_container} >
            <Button className={styles.button_download}>
            <img src={appstore} alt="download on App Store" width={`100%`} height={`100%`} />
                {/* <div className={styles.icon}>
                    <FaApple />
                </div>
                <div className={styles.text_container}>
                    <span>{apple}</span>
                    <span className={styles.span}>App Store</span>
                </div> */}
            </Button>

            <Button className={styles.button_download}>
                <img src={playstore} alt="android app on Google Play" width={`100%`} height={`100%`} />
                {/* <div className={styles.icon}>
                    <FaGooglePlay />
                </div>
                <div className={styles.text_container}>
                    <span>{google}</span>
                    <span className={styles.span}>Google Play</span>
                </div> */}
            </Button>
        </div>
    );
}

export default StoreButtons;
