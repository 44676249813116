import React, { useState } from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { InputField, ModalComponent } from "../../../UI";

import styles from "./Register.module.scss";

const RegisterModal = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { t } = useTranslation("auth");

    const handleShowRegister = () => setShowRegister(true);
    const handleCloseRegister = () => setShowRegister(false);

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const registerForm = (
        <>
            <InputField
                controlId="formRegisterEmail"
                label="Email"
                type="email"
                placeholder={t("input_placeholder_email")}
                value={email}
                onChange={handleEmailChange}
            />
            <InputField
                controlId="formRegisterPassword"
                label={t("input_label_password")}
                type="password"
                placeholder={t("input_placeholder_password")}
                value={password}
                onChange={handlePasswordChange}
            />
        </>
    );

    const registerButton = (
        <Button variant="primary" onClick={handleCloseRegister}>
            {t("button_text_register")}
        </Button>
    );

    return (
        <>
            <Button className={styles.register_button} onClick={handleShowRegister}>
                {t("button_text_register")}
            </Button>
            <ModalComponent
                show={showRegister}
                onHide={handleCloseRegister}
                title={t("title_modal_register")}
                children={registerForm}
                footerContent={registerButton}
            />
        </>
    );
};

export default RegisterModal;
