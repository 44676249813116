import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./TermsAndConditions.module.scss";

const TermsAndConditions = () => {

    const { t } = useTranslation("termsAndConditions");

    return (
        <Container fluid className={styles.container}>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h3>{t("title_main")}</h3>

                            <p>
                                {t("paragraph_main_first")}
                            </p>

                            <p>
                                {t("paragraph_main_second")}
                            </p>

                            <p>
                                {t("paragraph_main_third")}
                            </p>

                            <p>
                                {t("paragraph_main_fourth")}
                            </p>

                            <ul>
                                <li>
                                    <a
                                        href="https://www.google.com/policies/privacy/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Google Play Services
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://expo.io/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Expo
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.revenuecat.com/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        RevenueCat
                                    </a>
                                </li>
                            </ul>

                            <p>
                                {t("paragraph_main_fifth")}
                            </p>

                            <p>
                                {t("paragraph_main_sixth")}
                            </p>

                            <p>
                                {t("paragraph_main_seventh")}
                            </p>

                            <p>
                                {t("paragraph_main_eighth")}
                            </p>

                            <p>
                                {t("paragraph_main_ninth")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_changes_terms_conditions")}</h3>

                            <p>
                                {t("paragraph_changes_terms_conditions_first")}
                            </p>

                            <p>
                                {t("paragraph_changes_terms_conditions_second")}
                            </p>
                        </div>

                        <div>
                            <h3>{t("title_contact_us")}</h3>

                            <p>
                                {t("paragraph_contact_us")}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default TermsAndConditions;
