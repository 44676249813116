import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaTelegramPlane, FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Col, Container, Row } from "react-bootstrap";
import LanguageDropdown from "../Navibar/LanguageDropdown/LanguageDropdown";
import { useTranslation } from "react-i18next";

import styles from "./Footer.module.scss";

const Footer = () => {
    const navigate = useNavigate();

    const { t } = useTranslation("footer");

    const handlePricingClick = (e) => {
        e.preventDefault();
        navigate("/#pricing");
    };

    const handleOtherPageClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Container fluid className={styles.footer}>
            <Container>
                <Row>
                    <Col lg={3} md={4} className={styles.footer_block}>
                        <h3>{t("title_information")}</h3>
                        <ul className={styles.footer_block_list}>
                            <li className={styles.footer_list_item} onClick={handleOtherPageClick}>
                                <Link to="/privacy-policy">{t("link_privacy_policy")}</Link>
                            </li>
                            <li className={styles.footer_list_item} onClick={handleOtherPageClick}>
                                <Link to="/cookie-policy">{t("link_cookie_policy")}</Link>
                            </li>
                            <li className={styles.footer_list_item} onClick={handleOtherPageClick}>
                                <Link to="/terms-and-conditions">{t("link_terms_conditions")}</Link>
                            </li>
                            <li className={styles.footer_list_item}>
                                <a href="#pricing" onClick={handlePricingClick}>
                                    {t("link_prices_tariffs")}
                                </a>
                            </li>
                        </ul>
                    </Col>

                    <Col lg={3} md={4} className={styles.footer_block}>
                        <h3>{t("title_support")}</h3>
                        <ul className={styles.footer_block_list}>
                            <li className={styles.footer_list_item}>
                                {t("link_ask_question")}
                            </li>
                            <li className={styles.footer_list_item}>
                                <Link to="/support" onClick={handleOtherPageClick}>{t("link_feedback_suggestions")}</Link>
                            </li>
                            <li className={styles.footer_list_item}>
                                {t("link_report_violation")}
                            </li>
                        </ul>
                    </Col>

                    <Col lg={3} md={4} className={styles.footer_block}>
                        <h3>{t("title_help")}</h3>
                        <ul className={styles.footer_block_list}>
                            <li className={styles.footer_list_item} onClick={handleOtherPageClick}>
                                <Link to="/questions-and-answers">{t("link_questions_answers")}</Link>
                            </li>
                        </ul>
                    </Col>

                    <Col lg={3} className={styles.footer_copyright}>
                        <LanguageDropdown isFooter={true} />

                        <div>
                            <div className={styles.copyright_text}>
                                {t("title_copyright")}
                            </div>

                            <ul className={styles.social_icons}>
                                <li className={styles.social_icon}>
                                    <FaTelegramPlane />
                                </li>
                                <li className={styles.social_icon}>
                                    <FaFacebook />
                                </li>
                                <li className={styles.social_icon}>
                                    <FaInstagram />
                                </li>
                                <li className={styles.social_icon}>
                                    <FaXTwitter />
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Footer;
