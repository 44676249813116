import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CarouselImage from './CarouselImage/CarouselImage';
import styles from './Slider.module.scss';

const Slider = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const parallaxStyle = (multiplier, angleMultiplier) => ({
    transform: `translate(${mousePosition.x * multiplier}px, ${mousePosition.y * multiplier}px)
                rotate(${mousePosition.x * angleMultiplier}deg)`,
    transition: 'transform 0.1s ease-out',
  });

  const parallaxStyleReverse = (multiplier, angleMultiplier) => ({
    transform: `translate(${mousePosition.x * multiplier}px, ${mousePosition.y * multiplier}px)
                rotate(${mousePosition.x * -angleMultiplier}deg)`,
    transition: 'transform 0.1s ease-out',
  });

  return (
    <div className={styles.slider_container}>
      <div className={styles.parallax_wrapper}>
        <div className={styles.parallax_item} style={parallaxStyle(10, 10)}>
          <img src={require('../../../assets/gpt4.png')} alt="gpt4" />
        </div>
        <div className={styles.parallax_item} style={parallaxStyleReverse(20, 15)}>
          <img src={require('../../../assets/gemini.png')} alt="gemini" />
        </div>
        <div className={styles.parallax_item} style={parallaxStyle(30, 5)}>
          <img src={require('../../../assets/claude.png')} alt="claude" />
        </div>
        <div className={`${styles.circle} ${styles.circle1}`} style={parallaxStyleReverse(45, 15)} />
        <div className={`${styles.circle} ${styles.circle2}`} style={parallaxStyle(20, 10)} />
      </div>
      <Carousel fade controls={false} pause={false}>
        <Carousel.Item className={styles.carousel_item}>
          <CarouselImage text="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage text="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage text="Third slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Slider;