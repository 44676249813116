import React from "react";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import { questionsAndAnswersData } from "./questionsAndAnswersData";

import styles from "./QuestionsAndAnswers.module.scss";

const QuestionsAndAnswers = () => {
    return (
        <Container fluid className={styles.container}>
            <Container>
                <Row>
                    <Col>
                        <h3>Questions & Answers</h3>

                        <Accordion defaultActiveKey="0" className={styles.accordion_container}>
                            {questionsAndAnswersData.map((item) => (
                                <Accordion.Item eventKey={item.id} className={styles.accordion_item} key={item.id}>
                                    <Accordion.Header>
                                        {item.title}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {item.content}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default QuestionsAndAnswers;
