import React from "react";
import { Offcanvas } from "react-bootstrap";
// import Login from "../../Auth/Login/Login";
// import Register from "../../Auth/Register/Register";

import Menu from "../Menu/Menu";
import styles from "./Offcanvas.module.scss";
import Auth from "../../Auth/Auth";

function OffcanvasMenu({ show, handleClose }) {
    return (
        <Offcanvas
            variant="dark"
            show={show}
            onHide={handleClose}
            className={styles.container}
        >
            <Offcanvas.Header closeButton data-bs-theme="dark" >
                {/* <Offcanvas.Title>Menu</Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Menu />
                <div className={styles.auth}>
                    <Auth />
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default OffcanvasMenu;
