export const MAIN_EN = {
    title_span1: "and other popular",
    title_span2: "AI",
    title_span3: "in one service",
    description_title: "Talk to GPT chat, GPT-4o, Claude 3, DALLE 3 and others",
    get_started: "Get started FREE",
    download_button_apple: "Download on the",
    download_button_google: "GET IT ON",
    scroll_down: "scroll to see more",
    description_text_block_first:
        "Clicked AI is a set of tools with algorithms that work similarly to the human brain. These tools simplify the execution of various tasks, useful for both small business owners and ordinary people.",
    description_text_block_second: "The models in Clicked AI will help you solve tasks we often encounter on the internet: answering questions; writing, correcting, and improving texts; drawing pictures; translating texts and videos from different languages.",
};
