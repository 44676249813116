import React from "react";
import { Col, Row } from "react-bootstrap";

import styles from "./ModalDownloadContent.module.scss";

import StoreButtons from "../StoreButtons/StoreButtons";

const ModalDownloadContent = () => {
    return (
        // <div className={styles.button_container}>
        //     <Button className={styles.button_download}>
        //         <div className={styles.icon}>
        //             <FaApple />
        //         </div>
        //         <div className={styles.text_container}>
        //             <span>Download on the</span>
        //             <span className={styles.span}>App Store</span>
        //         </div>
        //     </Button>

        //     <Button className={styles.button_download}>
        //         <div className={styles.icon}>
        //             <FaGooglePlay />
        //         </div>
        //         <div className={styles.text_container}>
        //             <span>GET IT ON</span>
        //             <span className={styles.span}>Google Play</span>
        //         </div>
        //     </Button>
        // </div>

        <Row >
            <Col sm={6}>
                <img
                    src={require("../../assets/slide2.png")}
                    alt="download Ai App "
                    width={`100%`}
                    height={`100%`}
                    className={styles.image}
                />
            </Col>
            <Col sm={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <StoreButtons wrap={{ flexWrap: "wrap" }} />
            </Col>
        </Row>
    );
};

export default ModalDownloadContent;
