export const PRICING_EN = {
    title_main: "Subscription Plan",
    description_main: "Choose the right plan for you",
    free_description: "Ideal for personal use and exploring basic features.",
    pro_description: "Perfect for professionals needing more advanced features.",
    pro_plus_description: "Best for businesses requiring high performance and priority support.",
    button_get_started: "Get Started",
    
    features_description_standard: "Standard generations",
    features_description_advanced: "Advanced generations",
    features_description_image: "Image generations",

    features_description_standard_model: "GPT-3.5, Claude 3 Haiku, Gemini Pro & more",
    features_description_advanced_model: "GPT-4/4o, Claude 3 Sonnet/Opus & more",
    features_description_image_model: "Dalle 3, Stable Diffusion & more",

    features_description_support_free: "Customer Support",
    features_description_support_pro: "24/7 Customer Support",
    features_description_support_pro_plus: "24/7 Customer Support",
};