import React from "react";
import { Button } from "react-bootstrap";

import styles from "./ButtonBid.module.scss";

function ButtonBid({ onClick, name }) {
    return (
        <Button onClick={onClick} className={styles.bidBtn}>
            {name}
        </Button>
    );
}



export default ButtonBid;
