import React, { useState } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OffcanvasMenu from './Offcanvas/Offcanvas';
import Menu from "./Menu/Menu";
import Auth from "../Auth/Auth";
import LanguageDropdown from "./LanguageDropdown/LanguageDropdown";

import styles from "./Navibar.module.scss";

const Navibar = () => {
    // const [hideContentNavbar, setHideContentNavbar] = useState(false);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();
    // const location = useLocation();

    // useEffect(() => {
    //     setHideContentNavbar(
    //         location.pathname === "/privacy-policy" ||
    //             location.pathname === "/terms-and-conditions"
    //     );
    // }, [location]);

    const handleMainClick = (e) => {
        e.preventDefault();
        navigate("/#main");
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Navbar
            variant="dark"
            className={styles.navbar}
            fixed="top"
            expand="lg"
        >
            <Container >
                <div className={styles.burger_and_logo}>
                    <Navbar.Brand className={styles.navbar_logo_container}>
                        <Link
                            to="/"
                            className={styles.navbar_logo}
                            onClick={handleMainClick}
                        >
                            <p>ai.clicked</p>
                        </Link>
                        
                    </Navbar.Brand> 
                    <LanguageDropdown isFooter={false} />                   
                </div>
                
                <div className={styles.menu_block} >
                    <div className={styles.menu}>
                        <Menu />
                    </div>

                    <div className={`${styles.auth_buttons_container}`}>
                        <div className={styles.auth}>
                           <Auth /> 
                        </div>
                        
                        <Button onClick={handleShow} className={styles.mobile_menu_button}>
                            ☰
                        </Button>
                    </div>
                </div>
               <OffcanvasMenu show={show} handleClose={handleClose} />

            </Container>
        </Navbar>
    );
};

export default Navibar;
