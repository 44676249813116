import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import ModelCard from "./ModelCard/ModelCard";
import { imageModels, textModels } from "./models";
import ModalComponent from "../../UI/Modal/ModalComponent";
import ModalDownloadContent from "../ModalDownloadContent/ModalDownloadContent";
import { useTranslation } from "react-i18next";
import ButtonGravity from "../../UI/Buttons/ButtonGravity/ButtonGravity";

import styles from "./Content.module.scss";
import ButtonBid from "../../UI/Buttons/ButtonBid/ButtonBid";
import StoreButtons from './../StoreButtons/StoreButtons';

const Content = () => {
    const [modalShow, setModalShow] = useState(false);

    const { t } = useTranslation("content");

    const handleGetStartedClick = () => {
        setModalShow(true);
    };

    const handleClose = () => {
        setModalShow(false);
    };

    return (
        <>
            <Container fluid className={styles.content}>
                <Container className={styles.content_container}>
                    <Container className={styles.content_container_item}>
                        <h2>{t("title_text_generation")}</h2>

                        <div className={styles.generate_text_description}>
                            {t("description_text_generation")}
                        </div>

                        <div className={styles.generate_models}>
                            {textModels.map((model) => (
                                <ModelCard
                                    key={`model_text_${model.name}`}
                                    name={model.name}
                                    logo={model.logo}
                                    desc={model.desc}
                                />
                            ))}
                        </div>

                        {/* <ButtonGravity onClick={handleGetStartedClick} /> */}
                    </Container>

                    <Container className={styles.content_container_item}>
                        <h2>{t("title_image_generation")}</h2>

                        <div className={styles.generate_text_description}>
                            {t("description_image_generation")}
                        </div>

                        <div className={styles.generate_models}>
                            {imageModels.map((model) => (
                                <ModelCard
                                    key={`model_image_${model.name}`}
                                    name={model.name}
                                    logo={model.logo}
                                    desc={model.desc}
                                />
                            ))}
                        </div>

                        
                    </Container>
                    <ButtonBid onClick={handleGetStartedClick} name={t("get_started")} />
                    {/* <ButtonGravity onClick={handleGetStartedClick} /> */}
                </Container>
            </Container>

            <ModalComponent
                show={modalShow}
                onHide={handleClose}
                title={"Download App"}
                // footerContent={<Button onClick={handleClose}>Close</Button>}
            >
                <ModalDownloadContent />
                {/* <StoreButtons  wrap={{ flexWrap: "wrap" }} /> */}
            </ModalComponent>
        </>
    );
};

export default Content;
