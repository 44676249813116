export const FOOTER_RU = {
    title_information: "ИНФОРМАЦИЯ",
    title_support: "ПОДДЕРЖКА",
    title_help: "ПОМОЩЬ",
    title_copyright: "СДЕЛАНО CLICKED",

    link_privacy_policy: "Политика конфиденциальности",
    link_cookie_policy: "Политика cookie",
    link_terms_conditions: "Условия и положения",
    link_prices_tariffs: "Цены и тарифы",
    link_ask_question: "Задать вопрос",
    link_feedback_suggestions: "Обратная связь и предложения",
    link_report_violation: "Сообщить о нарушении",
    link_questions_answers: "Вопросы и ответы",
};