export const AUTH_EN = {
    button_text_login: "Log in",
    button_text_register: "Sign up",

    title_modal_login: "Login",
    title_modal_register: "Register",
    
    input_label_password: "Password",
    input_placeholder_password: "Enter your password",
    input_label_email: "Email",
    input_placeholder_email: "Enter your email",

    already_have_account: "Already have an account?",
    dont_have_account: "Don't have an account?",

    log_in: "Log in",
    sign_up_for_free: "Sign up for free",
    sign_up: "Sign up",

    signing_up_agree: "By signing up, you agree to the",
    terms_and_conditions: "Terms and Conditions",
    privacy_policy: "Privacy Policy",
    and_the: "and the",
};