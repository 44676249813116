import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import ModalComponent from "../../UI/Modal/ModalComponent";
import ModalDownloadContent from "../ModalDownloadContent/ModalDownloadContent";
import Slider from "./Slider/Slider";

import ButtonBid from "../../UI/Buttons/ButtonBid/ButtonBid";
import StoreButtons from "../StoreButtons/StoreButtons";
import styles from "./Main.module.scss";

const Main = () => {
    const [modalShow, setModalShow] = useState(false);

    const { t } = useTranslation("main");

    const scrollToContent = () => {
        const contentElement = document.getElementById("content");
        if (contentElement) {
            contentElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleGetStartedClick = () => {
        setModalShow(true);
    };

    const handleClose = () => {
        setModalShow(false);
    };

    return (
        <>
            <Container fluid className={styles.main}>
                <Container>
                    <Row className={styles.main_content}>
                        <Col sm={7}>
                            <h1 className={styles.main_title}>
                                <span className={styles.spanH1}>ChatGPT</span>{" "}
                                {t("title_span1")}{" "}
                                <span className={styles.spanH1}>
                                    {t("title_span2")}
                                </span>{" "}
                                {t("title_span3")}
                            </h1>
                            <div className={styles.description}>
                                <p>{t("description_title")}</p>
                            </div>

                            <ButtonBid
                                onClick={handleGetStartedClick}
                                name={t("get_started")}
                            />
                        </Col>

                        <Col sm={5}>
                            {/* <div className={styles.device_container}></div> */}
                            <Slider />
                            <StoreButtons wrap={{ flexWrap: "nowrap" }} />
                        </Col>
                    </Row>

                    <div className={styles.scroll_container}>
                        <div
                            className={styles.scroll_down}
                            onClick={scrollToContent}
                        >
                            <div className={styles.see_more}>
                                {t("scroll_down")}
                            </div>
                            <div className={styles.arrow_icon}>
                                <IoIosArrowDown />
                            </div>
                        </div>
                    </div>
                </Container>

                <Container>
                    <Row className={styles.main_text_content}>
                        <Col sm={6}>
                            <div className={styles.main_text}>
                                {t("description_text_block_first")}
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className={styles.main_text}>
                                {t("description_text_block_second")}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <ModalComponent
                show={modalShow}
                onHide={handleClose}
                title={"Download App"}
                // footerContent={<Button onClick={handleClose}>Close</Button>}
            >
                <ModalDownloadContent />
                {/* <StoreButtons  wrap={{ flexWrap: "wrap" }} /> */}
            </ModalComponent>
        </>
    );
};

export default Main;
