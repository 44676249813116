import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Form, Button } from "react-bootstrap";
import InputField from "../../UI/Input/InputField";

import styles from "./Support.module.scss";

const Support = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [id, setId] = useState("");

    const { t } = useTranslation("support");

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleNameChange = (e) => setName(e.target.value);
    const handleIdChange = (e) => setId(e.target.value);

    return (
        <Container fluid className={styles.container}>
            <Container className={styles.container_content}>

                <h3>{t("title_main")}</h3>

                <Form className={styles.modal_form}>
                    <InputField
                        controlId="formLoginEmail"
                        label="Email"
                        type="email"
                        placeholder={t("input_placeholder_email")}
                        value={email}
                        onChange={handleEmailChange}
                    />

                    <InputField
                        controlId="formName"
                        label="Name"
                        type="text"
                        placeholder={t("input_placeholder_name")}
                        value={name}
                        onChange={handleNameChange}
                    />

                    <InputField
                        controlId="formId"
                        label="ID"
                        type="number"
                        placeholder={t("input_placeholder_id")}
                        value={id}
                        onChange={handleIdChange}
                    />

                    <Form.Group controlId="yourMessage">
                        <Form.Label>{t("input_message")}</Form.Label>
                        <Form.Control className={styles.your_message_control} as="textarea" rows={3} />
                    </Form.Group>
                </Form>

                <Button variant="primary" className={styles.button}>
                    {t("button_text_send")}
                </Button>
            </Container>
        </Container>
    );
};

export default Support;
