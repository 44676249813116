import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";

import { MAIN_EN } from "./language/en/main";
import { MAIN_RU } from "./language/ru/main";
import { CONTENT_EN } from "./language/en/content";
import { CONTENT_RU } from "./language/ru/content";
import { PRICING_EN } from "./language/en/pricing";
import { PRICING_RU } from "./language/ru/pricing";
import { REVIEWS_EN } from "./language/en/reviews";
import { REVIEWS_RU } from "./language/ru/reviews";
import { NAVBAR_EN } from "./language/en/navbar";
import { NAVBAR_RU } from "./language/ru/navbar";
import { AUTH_EN } from "./language/en/auth";
import { AUTH_RU } from "./language/ru/auth";
import { FOOTER_EN } from "./language/en/footer";
import { FOOTER_RU } from "./language/ru/footer";
import { COOKIE_BANNER_EN } from "./language/en/cookieBanner";
import { COOKIE_BANNER_RU } from "./language/ru/cookieBanner";
import { COOKIE_MANAGE_EN } from "./language/en/cookieManage";
import { COOKIE_MANAGE_RU } from "./language/ru/cookieManage";
import { PRIVACY_POLICY_EN } from "./language/en/privacyPolicy";
import { PRIVACY_POLICY_RU } from "./language/ru/privacyPolicy";
import { COOKIE_POLICY_EN } from "./language/en/cookiePolicy";
import { COOKIE_POLICY_RU } from "./language/ru/cookiePolicy";
import { TERMS_AND_CONDITIONS_EN } from "./language/en/termsAndConditions";
import { TERMS_AND_CONDITIONS_RU } from "./language/ru/termsAndConditions";
import { SUPPORT_EN } from "./language/en/support";
import { SUPPORT_RU } from "./language/ru/support";


// console.log('LanguageDetector', LanguageDetector);

const options = {
	order: ['querystring', 'navigator'],
	lookupQuerystring: 'lng'
  }

i18n.use(XHR).use(LanguageDetector)
    .use(initReactI18next)

	.init({detection: options,
		ns: ['common'],
		defaultNS: 'common',
		fallbackLng: 'en',
		supportedLngs: ['de', 'en', 'es', 'fr', 'ru'],
		interpolation: {
		  escapeValue: false,
		},
		debug: false,
		resources: {
		  en: {
		    main: MAIN_EN,
			content: CONTENT_EN,
			pricing: PRICING_EN,
			reviews: REVIEWS_EN,
			navbar: NAVBAR_EN,
			auth: AUTH_EN,
			footer: FOOTER_EN,
			cookieBanner: COOKIE_BANNER_EN,
			cookieManage: COOKIE_MANAGE_EN,
			privacyPolicy: PRIVACY_POLICY_EN,
			cookiePolicy: COOKIE_POLICY_EN,
			termsAndConditions: TERMS_AND_CONDITIONS_EN,
			support: SUPPORT_EN,
		  },
		  ru: {
		    main: MAIN_RU,
			content: CONTENT_RU,
			pricing: PRICING_RU,
			reviews: REVIEWS_RU,
			navbar: NAVBAR_RU,
			auth: AUTH_RU,
			footer: FOOTER_RU,
			cookieBanner: COOKIE_BANNER_RU,
			cookieManage: COOKIE_MANAGE_RU,
			privacyPolicy: PRIVACY_POLICY_RU,
			cookiePolicy: COOKIE_POLICY_RU,
			termsAndConditions: TERMS_AND_CONDITIONS_RU,
			support: SUPPORT_RU,
		  }
		}
	  });

// i18n.changeLanguage("en");
