export const PRICING_RU = {
    title_main: "План подписки",
    description_main: "Выберите подходящий для вас тарифный план",
    free_description: "Идеально подходит для личного использования и изучения основных функций.",
    pro_description: "Идеально подходит для профессионалов, которым нужны более продвинутые функции.",
    pro_plus_description: "Лучше всего подходит для бизнеса, которому требуется высокая производительность и приоритетная поддержка.",
    button_get_started: "Начать",

    features_description_standard: "Стандартных генераций",
    features_description_advanced: "Продвинутых генераций",
    features_description_image: "Генераций изображений",

    features_description_standard_model: "GPT-3.5, Claude 3 Haiku, Gemini Pro и другие",
    features_description_advanced_model: "GPT-4/4o, Claude 3 Sonnet/Opus и другие",
    features_description_image_model: "Dalle 3, Stable Diffusion и другие",

    features_description_support_free: "Поддержка клиентов",
    features_description_support_pro: "Поддержка клиентов 24/7",
    features_description_support_pro_plus: "Приоритетная поддержка клиентов",
};