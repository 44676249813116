import { useTranslation } from "react-i18next";
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";
import CookieBanner from "./components/Cookie/CookieBanner/CookieBanner";
import Footer from "./components/Footer/Footer";
import Navibar from "./components/Navibar/Navibar";
import AuthPage from "./Pages/AuthPage/AuthPage";
import CookiePolicy from "./Pages/CookiePolicy/CookiePolicy";
import EuUkPrivacyPolicy from "./Pages/EuUkPrivacyPolicy/EuUkPrivacyPolicy";
import MainContent from "./Pages/MainContent/MainContent";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import QuestionsAndAnswers from "./Pages/QuestionsAndAnswers/QuestionsAndAnswers";
import Support from "./Pages/Support/Support";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import { ScrollProvider } from "./Provider/ScrollContext";

/* Для слайдера */
import "swiper/css";
import "swiper/css/pagination";
/* Для слайдера */

import "./index.scss";
import ProfileRemove from "./Pages/Profile/ProfileRemove/ProfileRemove";

function App() {
    const { i18n } = useTranslation();

    return (
        <ScrollProvider>
            <Router>
                <Navibar />
                <Routes>
                    <Route path="/" element={<MainContent />} />
                    <Route path="/auth-page" element={<AuthPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                    />
                    <Route
                        path="/questions-and-answers"
                        element={<QuestionsAndAnswers />}
                    />
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
                    <Route
                        path="/eu-uk-privacy-policy"
                        element={<EuUkPrivacyPolicy />}
                    />
                    <Route path="/support" element={<Support />} />
                    <Route path="/profile-remove" element={<ProfileRemove />} />

                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer />

                <CookieBanner />
            </Router>
        </ScrollProvider>
    );
}

export default App;
