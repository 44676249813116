import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { slidesData } from "../slidesData";
import { FaStar } from "react-icons/fa";

import styles from "./SliderReviews.module.scss";

const SliderReviews = () => {
    return (
        <>
            <Swiper
                slidesPerView={4.5}
                spaceBetween={30}
                grabCursor={true}
                pagination={{
                    clickable: true,
                }}
                modules={[]}
                className="mySwiper"
                breakpoints={{
                    290: {
                        slidesPerView: 1.5,
                        spaceBetween: 10,
                    },
                    490: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                    },
                    640: {
                        slidesPerView: 2.5,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3.5,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 3.5,
                        spaceBetween: 40,
                    },
                }}
            >
                {slidesData.map((slide) => (
                    <SwiperSlide key={slide.id}>
                        <div className={styles.stars}>
                            <FaStar size={20} />
                            <FaStar size={20} />
                            <FaStar size={20} />
                            <FaStar size={20} />
                            <FaStar size={20} />
                        </div>

                        <p>{slide.text}</p>

                        <p>
                            <strong>{slide.name}</strong>
                        </p>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default SliderReviews;
